import React, { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [products, setProducts] = useState([]);

  window.addEventListener('message', (e) => {
    const { type, payload } = e.data;
    if (type === 'ADD_PRODUCT') {
      let nProducts = [...products];
      const index = nProducts.findIndex((p) => p.name === payload.name);
      if (index > -1) {
        nProducts[index] = { ...nProducts[index], qty: nProducts[index].qty + 1 };
      } else {
        nProducts.push({ ...payload, qty: 1 });
      }
      setProducts(nProducts);
    }
  });

  const handleRemoveProduct = (product) => {
    let nProducts = [...products];
    const index = nProducts.findIndex((p) => p.name === product.name);
    nProducts[index] = { ...product, qty: product.qty - 1 };
    if (nProducts[index].qty === 0) {
      nProducts.splice(index, 1);
    }
    setProducts(nProducts);
    window.parent.postMessage({ type: 'RM_PRODUCT', payload: product }, window.location.origin);
  };

  const getTotal = () => {
    return '$' + products.reduce((acc, product) => acc + product.price * product.qty, 0).toFixed(2);
  }

  return (
    <div className="App">
      <main>
        <h2>Cart <small className='font-monospace'>(React)</small></h2>

        <ul className="list-group font-monospace">
          <li className="list-group-item d-flex">
            <span style={{ flex: 1 }}>Name</span>
            <span style={{ width: '10ch' }}>Quantity</span>
            <span style={{ width: '8ch' }}>Price</span>
            <button className='btn btn-outline-danger btn-sm visually-hidden'>
                <span className="material-symbols-outlined">delete</span>
            </button>
          </li>
          {products.length === 0 && (<span className='px-3'>Your cart is empty</span>)}
          {products.map((product) => (
            <li className="list-group-item d-flex" key={product.name}>
              <span style={{ flex: 1, }}>{product.name}</span>
              <span style={{ width: '8ch', textAlign: 'center' }}>{product.qty}</span>
              <span style={{ width: '8ch', textAlign: 'center' }}>${product.price}</span>
              <button onClick={() => handleRemoveProduct(product)} className='btn btn-outline-danger btn-sm'>
                <span className="material-symbols-outlined">delete</span>
              </button>
            </li>
          ))}
          {products.length > 0 && (
            <li className="list-group-item d-flex">
              <span style={{ flex: 1, textAlign: 'right' }}>Total</span>
              <span style={{ width: '8ch' }}></span>
              <span style={{ width: '8ch', textAlign: 'center' }}>{getTotal()}</span>
            </li>
          )}
        </ul>
      </main>
    </div>
  );
}

export default App;
